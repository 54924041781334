.hidden {
	display: none !important;
	visibility: hidden;
}

.opacity0{
	opacity: 0!important;
}

.disable-scroll {
	overflow: hidden;
}

.inner-animation{
	transition: all .8s;
	&.not-visible{
		opacity: 0;
		transform: translateY(3rem)!important;
	}
}

.show-nav{
	// transform: translateY(0%)!important;
	opacity: 1!important;
	pointer-events: auto!important;
	ul{
		transform: translateY(0)!important;
	}
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
}

.menu .active{
	a{
		color: $black;
	}
}