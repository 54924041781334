.back-button{
	position: absolute;
	top: 3rem;
	left: 3rem;
	font-family: "museo-sans";
	font-weight: 700;
	font-size: 3.2rem;
	color: $red;

	.arrow{
		display: inline;
		width: 15px;
	}
}