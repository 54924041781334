.video{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 800px;
	height: 450px;
	background: $white;
}