.case{
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	max-width: 220px;
	display: inline-block;
	margin-right: 1rem;
height: 100%;

	.overlay{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		// background: $black;
		background: linear-gradient(to bottom, rgba(0,0,0,.6) 60%,rgba(0,0,0,1) 110%);
		opacity: .6;
	}

	span{
		font-family: "museo-sans";
		position: absolute;
		left: 1.5rem;
		right: 1.5rem;
		font-size: 1.2rem;

		&.title{
			color: $white;
			bottom: 1.5rem;
			font-weight: 700;
		}
		&.excerpt{
			color: $white;
			bottom: 10px;
			font-weight: 500;
			display: none;
		}
	}

	.overlay, .thumb{
		border-radius: 7px;
		object-fit: cover;
		// height: 150px;
height: 100%;
	}
	.play-button{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}