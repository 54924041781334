.single-container{
	background: $gray;
	position: relative;
	height: 100vh;

	.saw-logo{
		top: 1.5rem;
	}
	.saw-ring.saw-ring-4{
		max-width: 500px;
		top: unset;
		left: unset;
		right: -100px;
		bottom: -100px;
	}
}