.saw-ring{
	position: absolute;
	width: 100%;

	&.saw-ring-1{
		max-width: 720px;
		top: -60px;
		left: -150px;
		z-index: 1;
	}
	&.saw-ring-2{
		max-width: 30vw;
		bottom: 1.5rem;
		left: 0;
		right: 0;
		margin: auto;
	}
	&.saw-ring-3{
		max-width: 30vw;
		top: 1.5rem;
		right: 3rem;
	}

	@media(max-width: 768px){
		&.saw-ring-2{
			max-width: 260px;
			left: 10rem;
			right: unset;
		}
		&.saw-ring-3{
			max-width: 300px;
			top: 19rem;
		}
	}
}