.not-supported{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: $black;
	z-index: 999999;

	display: flex;
	justify-content: center;
	align-items: center;


	@media(max-width: 1024px){
		display: none;
	}

	span{
		text-align: center;
		font-size: 2rem;
		color: $white;
	}

	display: none;
}